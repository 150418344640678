import { Icons } from "components/molecules/icon/icon.interface";

export type WarningBlockProps = {
    message: string;
    iconName?: Icons;
    testId?: string;
    buttonText?: string;
    onButtonClick?: () => void;
    buttonWidth?: number;
    buttonTestId?: string;
};

export const testIds = {
    warningBlock: "warning-block",
    warningBlockButton: "warning-block-button",
};
