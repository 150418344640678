import { PropsWithChildren } from "react";
import { UseQueryResult } from "@tanstack/react-query";

import { Spinner } from "components/atoms/spinner/spinner.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { StyledLoaderContent } from "../page-info-block.styled";

type ImagesLoaderProps = PropsWithChildren<{
    queries: UseQueryResult<unknown, any>[];
}>;

export const ImagesLoader = ({ queries, children }: ImagesLoaderProps) => {
    if (queries.some((query) => query.isFetching)) {
        return (
            <StyledLoaderContent>
                <Spinner>
                    <Icon name={Icons.loaderSvg} />
                </Spinner>
            </StyledLoaderContent>
        );
    }

    return <>{children}</>;
};
