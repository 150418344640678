import { useQueryClient } from "@tanstack/react-query";

export const useClearQueryClient = () => {
    const queryClient = useQueryClient();

    return async () => {
        await queryClient.cancelQueries();
        queryClient.clear();
    };
};
