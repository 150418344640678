import { Popover } from "@mantine/core";
import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledPopover = styled(Popover)`
    min-width: 24px;
`;

export const StyledPopoverTarget = styled(Popover.Target)``;
export const StyledPopoverDropdown = styled(Popover.Dropdown)`
    color: ${colors.black_1};
    font-size: 13px;
    font-weight: 400;
    max-width: 198px;
    width: max-content;
    word-break: break-word;
`;

export const StyledButton = styled.button`
    width: max-content;
    height: max-content;
    display: flex;
    padding: 0;
    background: transparent;
    border: none;
`;
