export enum Permission {
    VIEW_GAMES = "view_games",
    MAKE_GAMES_AVAILABLE_TO_B2B_COMPANY = "make_games_available_to_b2b_company",
    MANAGE_GAMES = "manage_games",
    VIEW_BLACK_LIST = "view_black_list",
    MANAGE_BLACK_LIST = "manage_black_list",

    VIEW_TAGS = "view_tags",
    MANAGE_TAGS = "manage_tags",

    VIEW_VENDORS = "view_vendors",
    GRANT_ACCESS_TO_VENDOR = "grant_access_to_vendor",

    VIEW_USERS = "view_users",
    CREATE_B2B_USER = "create_b2b_user",
    RESET_2FA = "reset_2fa",
    ENABLE_DISABLE_2FA = "enable_disable_2fa",
    MANAGE_B2B_USERS = "manage_b2b_users",
    CREATE_AGG_USER = "create_aggregator_user",
    MANAGE_AGGREGATOR_USERS = "manage_aggregator_users",
    CREATE_VENDOR_USERS = "create_vendor_users",
    MANAGE_VENDOR_USERS = "manage_vendor_users",
    MANAGE_VENDOR = "manage_vendor",

    VIEW_SUB_ROLES = "view_sub_roles",
    GRANT_PERMISSION_TO_SUB_ROLE = "grant_permission_to_sub_role",
    GRANT_SUB_ROLE_TO_USER = "grant_sub_role_to_user",
    MANAGE_SUB_ROLES = "manage_sub_roles",

    VIEW_B2B_COMPANY = "view_b2b_company",
    MANAGE_B2B_COMPANY_API_KEY = "manage_b2b_company_api_key",
    VIEW_B2B_COMPANY_API_KEY = "view_b2b_company_api_key",
    BLOCK_UNBLOCK_B2B_UNIT_AND_PROJ = "block_unblock_b2b_unit_and_proj",
    MANAGE_B2B_UNIT_AND_PROJ = "manage_b2b_unit_and_proj",

    VIEW_CONNECTION = "view_connection",
    MANAGE_CONNECTION = "manage_connection",

    VIEW_ACCESS_B2B_TO_USERS = "view_access_b2b_to_users",
    GRANT_ACCESS_B2B_TO_USERS = "grant_access_b2b_to_users",

    CREATE_B2B_ROOT = "create_b2b_root",
    MANAGE_B2B_ROOT = "manage_b2b_root",
    BLOCK_UNBLOCK_B2B_ROOT = "block_unblock_b2b_root",

    GRANT_ATTRIBUTES_TO_USERS = "grant_attributes_to_users",
    VIEW_USER_ATTRIBUTES = "view_user_attributes",

    MANAGE_AGG_ANALYTICS = "manage_agg_analytics",
    MANAGE_B2B_ANALYTICS = "manage_b2b_analytics",
    MANAGE_VENDOR_ANALYTICS = "manage_vendor_analytics",
    MANAGE_CLIENT_ANALYTICS = "manage_client_analytics",
    MANAGE_ANALYTICS_OBJECT = "manage_analytics_object",
}
