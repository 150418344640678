import styled, { css } from "styled-components";
import { Button } from "@mantine/core";

import { colors } from "lib/palette";
import { ButtonSize, IButtonStyledProps } from "./button.interface";

const sizes = {
    [ButtonSize.EXTRA_SMALL]: "32px",
    [ButtonSize.SMALL]: "36px",
    [ButtonSize.MEDIUM]: "44px",
    [ButtonSize.LARGE]: "54px",
};

export const buttonTypes = {
    primary: {
        color: colors.white,
        ["background-color"]: colors.purple_2,
    },
    outlined: {
        color: colors.purple_2,
        ["background-color"]: colors.white,
    },
    ["outlined-grey"]: {
        color: colors.black_1,
        ["background-color"]: colors.white,
    },
};

export const StyledButton = styled(Button)<IButtonStyledProps>`
    position: relative;
    width: ${({ $isFullWidth, width }) => {
        if (width) {
            return `${width}px`;
        }
        return $isFullWidth ? "100%" : "max-content";
    }};
    max-width: 100%;
    height: ${({ $size }) => sizes[$size]};
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background-color: ${({ variant = "primary", disabled, $loading }) =>
        disabled && !$loading
            ? colors.black_4
            : buttonTypes[`${variant}`]["background-color"]};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    border: none;

    &:active {
        transform: none;
    }

    .mantine-Button-inner {
        max-width: 100%;

        .mantine-Button-label {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            height: max-content;
        }
    }

    ${({ $size }) =>
        ($size === ButtonSize.EXTRA_SMALL || $size === ButtonSize.SMALL) &&
        `
        padding: 5px 20px;
    `};

    svg {
        width: ${({ $size }) =>
            sizes[$size] === "32px" || sizes[$size] === "px" ? "16px" : "24px"};
    }

    pointer-events: ${({ disabled, $loading }) =>
        disabled || $loading ? "none" : "initial"};

    ${({ variant }) =>
        (variant === "primary" &&
            css<IButtonStyledProps>`
                ${({ $loading }) =>
                    $loading &&
                    css<IButtonStyledProps>`
                        height: ${({ $size }) => sizes[$size]};
                        background-color: ${colors.purple_2};
                    `}

                background-color: ${({
                    backgroundColor = colors.purple_2,
                    disabled,
                    $loading,
                }) =>
                    disabled && !$loading
                        ? colors.black_4
                        : backgroundColor} !important;
                color: ${({
                    buttonColor = colors.white,
                    disabled,
                    $loading,
                }) => {
                    if ($loading) {
                        return colors.transparent;
                    } else if (disabled) {
                        return colors.black_2;
                    } else {
                        return buttonColor;
                    }
                }} !important;

                svg {
                    path {
                        stroke: ${({ buttonColor = colors.white }) =>
                            buttonColor};
                    }
                }

                &:hover {
                    background-color: ${({
                        backgroundColor = colors.purple_1,
                    }) => backgroundColor} !important;
                    cursor: pointer;
                }

                &:active {
                    background-color: ${({
                        backgroundColor = colors.purple_2,
                    }) => backgroundColor} !important;
                }
            `) ||
        (variant === "outlined" &&
            css<IButtonStyledProps>`
                background-color: ${({
                    backgroundColor = colors.white,
                    disabled,
                    $loading,
                }) =>
                    disabled && !$loading
                        ? colors.black_4
                        : backgroundColor} !important;
                color: ${({
                    buttonColor = colors.purple_2,
                    disabled,
                    $loading,
                }) => {
                    if ($loading) {
                        return colors.transparent;
                    } else if (disabled) {
                        return colors.black_2;
                    } else {
                        return buttonColor;
                    }
                }} !important;
                border: 1px solid ${colors.purple_3};

                svg {
                    path {
                        stroke: rgb(125 75 232 / 100%) !important;
                    }
                }
                ${({ disabled }) =>
                    disabled &&
                    css`
                        color: ${colors.black_2} !important;
                        border: none;
                    `};

                ${({ $loading }) =>
                    $loading &&
                    `
                border: 1px solid ${colors.black_3} ;
            `};

                &:hover {
                    border: 1px solid
                        ${({ $loading }) =>
                            !$loading ? colors.purple_1 : colors.black_3};
                    background-color: ${({
                        backgroundColor = colors.white,
                        disabled,
                        $loading,
                    }) =>
                        disabled && !$loading
                            ? colors.black_4
                            : backgroundColor};
                    cursor: pointer;
                }

                &:active {
                    border: 1px solid
                        ${({ $loading }) =>
                            !$loading ? colors.purple_2 : colors.black_3};
                    background-color: ${({
                        backgroundColor = colors.white,
                        disabled,
                        $loading,
                    }) =>
                        disabled && !$loading
                            ? colors.black_4
                            : backgroundColor};
                }
            `) ||
        (variant === "outlined-grey" &&
            css<IButtonStyledProps>`
                background-color: ${({
                    backgroundColor = colors.white,
                    disabled,
                    $loading,
                }) =>
                    disabled && !$loading
                        ? colors.black_4
                        : backgroundColor} !important;
                color: ${({
                    buttonColor = colors.black_1,
                    disabled,
                    $loading,
                }) => {
                    if ($loading) {
                        return colors.transparent;
                    } else if (disabled) {
                        return colors.black_2;
                    } else {
                        return buttonColor;
                    }
                }} !important;
                border: 1px solid ${colors.purple_3};

                svg {
                    path {
                        stroke: ${({ buttonColor = colors.black_1 }) =>
                            buttonColor};
                    }
                }

                ${({ disabled }) =>
                    disabled &&
                    `
                color: ${colors.black_2};
                border: none;
            `};

                ${({ $loading }) =>
                    $loading &&
                    `
                border: 1px solid ${colors.black_3} ;
            `};

                &:hover {
                    border: 1px solid
                        ${({ $loading }) =>
                            !$loading ? colors.black_2 : colors.black_3};
                    background-color: ${({
                        backgroundColor = colors.white,
                        disabled,
                        $loading,
                    }) =>
                        disabled && !$loading
                            ? colors.black_4
                            : backgroundColor};
                    cursor: pointer;
                }

                &:active {
                    border: 1px solid
                        ${({ $loading }) =>
                            !$loading ? colors.black_2 : colors.black_3};
                    background-color: ${({
                        backgroundColor = colors.white,
                        disabled,
                        $loading,
                    }) =>
                        disabled && !$loading
                            ? colors.black_4
                            : backgroundColor};
                }
            `)}
`;

export const StyledLoaderBlock = styled.div`
    width: max-content;
    height: max-content;
    display: flex;
    position: absolute;
    margin: auto;
    inset: 0;
`;
