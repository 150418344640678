import { colors } from "lib/palette";
import { Text } from "components/atoms/text/text.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { testIds, WarningBlockProps } from "./warning-block.interface";
import { StyledContent, StyledMessageBlock } from "./warning-block.styled";
import { Button } from "components/atoms/button/button.component";

export const WarningBlock = ({
    message,
    iconName = Icons.warningYellowIconSvg,
    testId = testIds.warningBlock,
    buttonText,
    onButtonClick,
    buttonWidth,
    buttonTestId = testIds.warningBlockButton,
}: WarningBlockProps) => {
    return (
        <StyledContent data-testid={testId}>
            <Icon name={iconName} />
            <StyledMessageBlock>
                <Text align="center" size={14} color={colors.black_6}>
                    {message}
                </Text>
            </StyledMessageBlock>
            {!!buttonText && !!onButtonClick && (
                <Button
                    onClick={onButtonClick}
                    data-testid={buttonTestId}
                    {...(buttonWidth
                        ? { width: buttonWidth, showTitle: true }
                        : {})}
                >
                    {buttonText}
                </Button>
            )}
        </StyledContent>
    );
};
