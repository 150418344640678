import { PropsWithChildren } from "react";

import { StyledChild, StyledContent } from "./spinner.styled";

export const Spinner = ({ children, ...props }: PropsWithChildren) => {
    return (
        <StyledContent {...props}>
            <StyledChild>{children}</StyledChild>
        </StyledContent>
    );
};
