import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { useGetZendeskArticleQuery } from "lib/api/hooks/use-get-zendesk-article.hook";
import { FallbackQuery } from "components/organisms/fallback-query/fallback-query.component";
import { Button } from "components/atoms/button/button.component";
import { useImages } from "../hooks/use-images.hook";
import { generatePageInfoIframeDoc } from "components/organisms/page-info/utils/generate-page-info-iframe-doc/generate-page-info-iframe-doc";
import {
    StyledBody,
    StyledCloseButton,
    StyledContent,
    StyledFooter,
    StyledIframe,
} from "../page-info-block.styled";
import {
    ArticleProps,
    ZENDESK_ARTICLE_BLOCK_ID,
    testIds,
} from "../page-info-block.interface";
import { ImagesLoader } from "./images-loader";

export const Article = ({
    articleId,
    closeDrawer,
    handleSSORedirect,
}: ArticleProps) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { t } = useTranslation("common");
    const router = useRouter();

    const zendeskArticleQuery = useGetZendeskArticleQuery(articleId);
    const { mappedHtmlWithImages, imageQueries } = useImages(
        zendeskArticleQuery.data?.article.body || "",
    );

    useEffect(() => {
        const handleIframeMessage = (event: MessageEvent) => {
            if (event.source !== iframeRef.current?.contentWindow) {
                return;
            }

            handleSSORedirect(event.data);
        };

        window.addEventListener("message", handleIframeMessage);

        return () => {
            window.removeEventListener("message", handleIframeMessage);
        };
    }, [handleSSORedirect, router, zendeskArticleQuery.isLoading]);

    return (
        <StyledBody>
            <StyledContent>
                <StyledCloseButton onClick={closeDrawer} />
                <FallbackQuery queries={[zendeskArticleQuery]}>
                    <ImagesLoader queries={imageQueries}>
                        <StyledIframe
                            ref={iframeRef}
                            data-testid={testIds.pageInfoBlock}
                            id={ZENDESK_ARTICLE_BLOCK_ID}
                            srcDoc={generatePageInfoIframeDoc({
                                title: zendeskArticleQuery.data?.article.title,
                                body: mappedHtmlWithImages,
                            })}
                        />
                    </ImagesLoader>
                </FallbackQuery>
            </StyledContent>

            <StyledFooter>
                <Button
                    width={110}
                    onClick={() => handleSSORedirect(articleId)}
                    data-testid={testIds.readMoreButton}
                >
                    {t("pageInfoDrawer.button.readMore", "Read more")}
                </Button>
            </StyledFooter>
        </StyledBody>
    );
};
