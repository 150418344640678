import styled from "styled-components";

import { FooterProps } from "./footer.interface";
import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledFooter = styled.div<FooterProps>`
    flex-shrink: 0;
    background-color: ${colors.white};
    border-top: 1px solid ${colors.blue_3};
    height: 60px;
    padding-left: calc(
        ${({ isActive }) => (isActive ? "240px" : "64px")} + 30px
    );
    padding-right: ${({ isRightBlockOpen }) =>
        isRightBlockOpen ? "340px" : "0"};
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: ${zIndexes.footer};
    box-shadow: 0 -2px 16px rgb(0 0 0 / 10%);

    ${({ isMobile }) =>
        isMobile &&
        `
      padding-left: 16px;
      padding-right: 16px;
    `};
`;

export const StyledFooterContent = styled.div`
    width: 100%;
    max-width: 730px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`;
