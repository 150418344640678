import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { AuthService } from "../api";
import { V1GetZendeskArticleResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";

export const useGetZendeskArticleQueryKey = "get-zendesk-article";

type Options = Omit<
    UseQueryOptions<
        V1GetZendeskArticleResponse,
        HttpErrorType,
        V1GetZendeskArticleResponse,
        [typeof useGetZendeskArticleQueryKey, string]
    >,
    "queryKey" | "queryFn"
>;

export function useGetZendeskArticleQuery(id: string, options?: Options) {
    return useQuery(
        [useGetZendeskArticleQueryKey, id],
        async () => {
            return (await AuthService.authServiceGetZendeskArticle(id)).data;
        },
        options,
    );
}
