import { Menu } from "@mantine/core";
import styled from "styled-components";

import { StyledMenuItemProps } from "./menu.interface";
import { colors } from "lib/palette";

export const StyledMenu = styled(Menu)``;
export const StyledMenuTarget = styled(Menu.Target)``;
export const StyledMenuDropdown = styled(Menu.Dropdown)`
    width: max-content;
    height: max-content;
    box-shadow: 0 2px 16px rgb(0 0 0 / 10%);

    .mantine-Menu-item {
        width: 100%;
    }

    padding: 0;

    & > div {
        padding: 10px 0;
    }
`;
export const StyledMenuItem = styled(Menu.Item)<StyledMenuItemProps>`
    padding: 2px 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_1};

    &:hover {
        background-color: ${colors.blue_4};
    }
`;

export const MenuButton = styled.div`
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    margin: 0;
    padding: 0;
`;

export const ActionButtonWrapper = styled.div`
    position: absolute;
    inset: 0;
`;
