import { TFunction } from "next-i18next";

import { generatePermissionsLabelsMap } from "lib/constants/permissions.constant";
import { getMissedPermissions } from "./get-missed-permissions";

export const DEFAULT_ERROR_MESSAGE_KEY =
    "common:permissionError.description.defaultMessage";
export const DEFAULT_ERROR_MESSAGE =
    "Permission denied. Contact your administrator.";

export const PERMISSION_CUSTOM_ERROR_MESSAGE_KEY =
    "common:permissionError.description.permissionsMessage";

export const generateMissedPermissionsErrorMessage = ({
    t,
    messages = [],
    customFallbackMessage,
}: {
    t: TFunction;
    messages?: string[];
    customFallbackMessage?: string;
}): string => {
    const permissionsLabelsMap = generatePermissionsLabelsMap(t);

    const missedPermissionsList = getMissedPermissions(messages);

    if (
        missedPermissionsList.length &&
        missedPermissionsList.every((key) => permissionsLabelsMap[key])
    ) {
        const messagePermissionsMessage = missedPermissionsList.reduce(
            (permissions, key, index, arr) => {
                if (index + 1 === arr.length) {
                    return permissions + permissionsLabelsMap[key];
                }
                return permissions + permissionsLabelsMap[key] + ", ";
            },
            "",
        );

        return t(PERMISSION_CUSTOM_ERROR_MESSAGE_KEY, {
            permissions: messagePermissionsMessage,
            defaultValue: `Your role does not have the following permissions: ${messagePermissionsMessage}. Contact your administrator for help.`,
        });
    }

    return (
        customFallbackMessage ||
        t(DEFAULT_ERROR_MESSAGE_KEY, DEFAULT_ERROR_MESSAGE)
    );
};
