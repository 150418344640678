import { useMutation } from "@tanstack/react-query";
import { useResetRecoilState } from "recoil";

import { AuthService } from "lib/api/api";
import { handleApiError } from "lib/error-handler";
import { userState } from "lib/recoil/atoms/user.atom";
import { useClearQueryClient } from "./use-clear-query-client.hook";
import { HttpErrorType } from "lib/api/types";

type AuthServiceLogout = Parameters<
    (typeof AuthService)["authServiceLogout"]
>["1"];

export function useLogout() {
    const resetUserData = useResetRecoilState(userState);
    const clearQueryClient = useClearQueryClient();

    const LogoutMutation = useMutation(
        async (args: AuthServiceLogout) => {
            return (
                await AuthService.authServiceLogout(
                    {},
                    {
                        ...args,
                    },
                )
            ).data;
        },
        {
            onError: ({ error }: HttpErrorType) => {
                void handleApiError({ error });
                resetUserData();
                void clearQueryClient();
            },
            onSuccess: () => {
                resetUserData();
                void clearQueryClient();
            },
        },
    );

    return {
        logout: () => {
            LogoutMutation.mutate({});
        },
    };
}
