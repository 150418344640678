import React from "react";

export interface RightModalProps extends React.PropsWithChildren {
    opened: boolean;
    onClose: () => void;
    showFooter?: boolean;
    okButtonText?: string;
    cancelButtonText?: string;
    onOk?: () => void;
    onCancel?: () => void;
    showCloseIcon?: boolean;
}

export const testIds = {
    modalContent: "modal-content",
    modalCloseButton: "modal-close-button",
};
