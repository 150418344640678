import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { TFunction } from "next-i18next";

import { Icons } from "components/molecules/icon/icon.interface";
import { generateMissedPermissionsErrorMessage } from "lib/utils/errors/generate-missed-permissions-message";

export type Errors = Record<
    number,
    {
        message: string;
        showRefetch: boolean;
        iconName?: Icons;
        generateErrorMessage?: (messages: string[]) => string;
    }
>;
export interface FallbackQueryProps {
    queries: UseQueryResult<unknown, any>[];
    errors?: Errors;
    errorMessage?: string;
    showProgress?: boolean;
    children?: React.ReactNode;
}

export const testIds = {
    fallbackLoader: "fallback-loader",
    fallbackRingProgress: "fallback-ring-progress",
    fallbackQueryComponent: "fallback-query-component",
};

export const FALLBACK_DEFAULT_ERROR_MESSAGE_KEY =
    "fallbackQuery.description.somethingWentWrong";
export const FALLBACK_PERMISSION_ERROR_MESSAGE =
    "You do not have sufficient access rights. Please contact your administrator.";

export const REFETCH_BUTTON_LABEL_KEY = "fallbackQuery.button.tryAgain";

export const FALLBACK_PERMISSION_ERROR_MESSAGE_KEY =
    "common:fallbackQuery.description.doNotHaveSufficientAccessRights";
export const FALLBACK_PERMISSION_CUSTOM_ERROR_MESSAGE_KEY =
    "common:fallbackQuery.description.permissionsMessage";

export const generatePermissionDeniedError = (
    errorCode: number,
    commonT: TFunction,
) => {
    return {
        [errorCode]: {
            message: commonT(
                FALLBACK_PERMISSION_ERROR_MESSAGE_KEY,
                FALLBACK_PERMISSION_ERROR_MESSAGE,
            ),
            showRefetch: false,
            iconName: Icons.warningYellowIconSvg,
            generateErrorMessage(messages: string[]) {
                return generateMissedPermissionsErrorMessage({
                    t: commonT,
                    messages,
                    customFallbackMessage: commonT(
                        FALLBACK_PERMISSION_ERROR_MESSAGE_KEY,
                        FALLBACK_PERMISSION_ERROR_MESSAGE,
                    ),
                });
            },
        },
    };
};
