import styled from "styled-components";

import { Dropdown } from "components/atoms/dropdown/dropdown.component";
import { breakpoints } from "lib/breakpoints";

export const StyledDropdown = styled(Dropdown)`
    width: 80px;
    margin-left: 12px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        margin-left: 4px;
    }
`;
