export type PageInfoBlockProps = {
    articleId?: string;
    closeDrawer: () => void;
};

export const testIds = {
    pageInfoBlock: "page-info-block",
    readMoreButton: "read-more-button",
    goToArticlesButton: "go-to-articles-button",
};

export const ZENDESK_ARTICLE_BLOCK_ID = "zendesk-article-block";

export type ArticleProps = {
    articleId: string;
    closeDrawer: () => void;
    handleSSORedirect: (path: string) => void;
};
