import { Permission } from "lib/constants/permissions";

export const generatePermissionsLabelsMap = (
    t: (key: string, defaultValue: string) => string,
): Record<string, string> => ({
    [Permission.VIEW_GAMES]: t(
        "common:permissions.label.readGame",
        "Read game",
    ),
    [Permission.MAKE_GAMES_AVAILABLE_TO_B2B_COMPANY]: t(
        "common:permissions.label.grantGamesToB2bCompany",
        "Grant games to 'B2B' company",
    ),
    [Permission.MANAGE_GAMES]: t(
        "common:permissions.label.updateGame",
        "Update game",
    ),
    [Permission.VIEW_BLACK_LIST]: t(
        "common:permissions.label.readBlackList",
        "Read black list",
    ),
    [Permission.MANAGE_BLACK_LIST]: t(
        "common:permissions.label.updateBlackList",
        "Update black list",
    ),

    [Permission.VIEW_TAGS]: t("common:permissions.label.readTag", "Read tag"),
    [Permission.MANAGE_TAGS]: t(
        "common:permissions.label.updateTag",
        "Update tag",
    ),

    [Permission.VIEW_VENDORS]: t(
        "common:permissions.label.readProvider",
        "Read Provider",
    ),
    [Permission.GRANT_ACCESS_TO_VENDOR]: t(
        "common:permissions.label.updateProviderAccess",
        "Update Provider's access",
    ),

    [Permission.VIEW_USERS]: t(
        "common:permissions.label.readUser",
        "Read user",
    ),
    [Permission.CREATE_B2B_USER]: t(
        "common:permissions.label.createB2bUser",
        "Create 'B2B' user",
    ),
    [Permission.RESET_2FA]: t("common:permissions.label.reset2fa", "Reset 2FA"),
    [Permission.ENABLE_DISABLE_2FA]: t(
        "common:permissions.label.enableDisable2fa",
        "Enable/disable 2FA",
    ),
    [Permission.MANAGE_B2B_USERS]: t(
        "common:permissions.label.updateB2bUser",
        "Update 'B2B' user",
    ),
    [Permission.CREATE_AGG_USER]: t(
        "common:permissions.label.createAggUser",
        "Create 'AGG' user",
    ),
    [Permission.MANAGE_AGGREGATOR_USERS]: t(
        "common:permissions.label.updateAggUser",
        "Update 'AGG' user",
    ),
    [Permission.CREATE_VENDOR_USERS]: t(
        "common:permissions.label.createProviderUser",
        "Create 'Provider' user",
    ),
    [Permission.MANAGE_VENDOR_USERS]: t(
        "common:permissions.label.updateProviderUser",
        "Update 'Provider' user",
    ),
    [Permission.MANAGE_VENDOR]: t(
        "common:permissions.label.manageProvider",
        "Manage provider",
    ),

    [Permission.VIEW_SUB_ROLES]: t(
        "common:permissions.label.readSubrole",
        "Read sub-role",
    ),
    [Permission.GRANT_PERMISSION_TO_SUB_ROLE]: t(
        "common:permissions.label.updateSubrolePermissions",
        "Update sub-role permissions",
    ),
    [Permission.GRANT_SUB_ROLE_TO_USER]: t(
        "common:permissions.label.updateUsersSubRole",
        "Update user's sub-role",
    ),
    [Permission.MANAGE_SUB_ROLES]: t(
        "common:permissions.label.updateSubrole",
        "Update sub-role",
    ),

    [Permission.VIEW_B2B_COMPANY]: t(
        "common:permissions.label.readB2bCompany",
        "Read 'B2B' company",
    ),
    [Permission.MANAGE_B2B_COMPANY_API_KEY]: t(
        "common:permissions.label.updateB2bCompanyApiKey",
        "Update 'B2B' company API Key",
    ),
    [Permission.VIEW_B2B_COMPANY_API_KEY]: t(
        "common:permissions.label.readB2bCompanyApiKey",
        "Read 'B2B' company API Key",
    ),
    [Permission.BLOCK_UNBLOCK_B2B_UNIT_AND_PROJ]: t(
        "common:permissions.label.blockUnblockB2bCompanyStructure",
        "Block/unblock 'B2B' company structure",
    ),
    [Permission.MANAGE_B2B_UNIT_AND_PROJ]: t(
        "common:permissions.label.updateB2bCompany",
        "Update 'B2B' company",
    ),

    [Permission.VIEW_CONNECTION]: t(
        "common:permissions.label.readConnection",
        "Read connection",
    ),
    [Permission.MANAGE_CONNECTION]: t(
        "common:permissions.label.updateConnection",
        "Update connection",
    ),

    [Permission.VIEW_ACCESS_B2B_TO_USERS]: t(
        "common:permissions.label.readB2bCompanyAccessForUser",
        "Read 'B2B' company access for user",
    ),
    [Permission.GRANT_ACCESS_B2B_TO_USERS]: t(
        "common:permissions.label.updateB2bCompanyAccessForUser",
        "Update 'B2B' company access for user",
    ),

    [Permission.CREATE_B2B_ROOT]: t(
        "common:permissions.label.createB2bRootCompany",
        "Create 'B2B-ROOT' company",
    ),
    [Permission.MANAGE_B2B_ROOT]: t(
        "common:permissions.label.updateB2bRootCompany",
        "Update 'B2B-ROOT' company",
    ),
    [Permission.BLOCK_UNBLOCK_B2B_ROOT]: t(
        "common:permissions.label.blockUnblockB2bRootCompany",
        "Block/unblock 'B2B-ROOT' company",
    ),

    [Permission.VIEW_USER_ATTRIBUTES]: t(
        "common:permissions.label.readUserAttributes",
        "Read user attributes",
    ),
    [Permission.GRANT_ATTRIBUTES_TO_USERS]: t(
        "common:permissions.label.updateUserAttributes",
        "Update user attributes",
    ),

    [Permission.MANAGE_AGG_ANALYTICS]: t(
        "common:permissions.label.manageAggCompanyReportsAndUserAccess",
        "Manage 'AGG' company reports and agg user access to reports",
    ),
    [Permission.MANAGE_B2B_ANALYTICS]: t(
        "common:permissions.label.manageB2bCompanyReportsAndUserAccess",
        "Manage 'B2B' company reports and b2b user access to reports",
    ),
    [Permission.MANAGE_VENDOR_ANALYTICS]: t(
        "common:permissions.label.manageProviderCompanyReportsAndUserAccess",
        "Manage 'Provider' company reports and Provider user access to reports",
    ),
    [Permission.MANAGE_CLIENT_ANALYTICS]: t(
        "common:permissions.label.manageAccessB2bProviderToReportsInB2bProviderCompanies",
        "Managing access of b2b and Provider users to reports in 'B2B' and 'Provider' companies",
    ),
    [Permission.MANAGE_ANALYTICS_OBJECT]: t(
        "common:permissions.label.manageFolders",
        "Manage the structure of the analytics service tree(Folders)",
    ),
});
