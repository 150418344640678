import React, { ReactNode } from "react";

export enum ButtonSize {
    EXTRA_SMALL = "extra-small",
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export enum ButtonValiant {
    PRIMARY = "primary",
    OUTLINED = "outlined",
    OUTLINED_GREY = "outlined-grey",
}

export type ButtonProps = React.PropsWithChildren & {
    backgroundColor?: string;
    buttonColor?: string;
    size?: ButtonSize | `${ButtonSize}`;
    onClick?: () => void;
    loading?: boolean;
    isFullWidth?: boolean;
    disabled?: boolean;
    variant?: ButtonValiant | `${ButtonValiant}`;
    type?: "submit" | "button";
    title?: string;
    width?: number;
    showTitle?: boolean;
};

export type IButtonStyledProps = {
    backgroundColor?: string;
    $size: ButtonSize | `${ButtonSize}`;
    buttonColor?: string;
    onClick?: () => void;
    $loading?: boolean;
    $isFullWidth?: boolean;
    disabled?: boolean;
    variant?: ButtonValiant | `${ButtonValiant}`;
    children: ReactNode;
    type?: "submit" | "button";
    width?: number;
};
