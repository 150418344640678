import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledContent = styled.div`
    width: max-content;
    height: max-content;
`;

export const StyledChild = styled.div`
    display: flex;
    width: max-content;
    height: max-content;
    animation: ${rotate} 1s linear infinite;
`;
