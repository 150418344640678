import { useMemo } from "react";

import { useGetZendeskArticleAttachmentsQuery } from "lib/api/hooks/use-get-zendesk-article-attachments.hook";
import {
    generateNewHtmlWithImages,
    generateSrcsAndImageIdsFromImgTags,
} from "./helpers";

const IMG_TAG_REGEX = /<img[^>]*\/?>/g;

export const useImages = (articleHtml: string) => {
    const imageTags = articleHtml.match(IMG_TAG_REGEX) || [];
    const { invalidImgTagsToRemove, imageIds, mappedImageInfo } =
        generateSrcsAndImageIdsFromImgTags(imageTags);

    const imageQueries = useGetZendeskArticleAttachmentsQuery(imageIds, {
        enabled: imageIds.length > 0,
    });

    const mappedHtmlWithImages = useMemo(() => {
        imageQueries.forEach((imageQuery, index) => {
            if (imageQuery.data?.image) {
                mappedImageInfo[imageIds[index]].imageUrl =
                    imageQuery.data.image;
            }
        });

        return generateNewHtmlWithImages(
            articleHtml,
            mappedImageInfo,
            invalidImgTagsToRemove,
        );
    }, [articleHtml, imageQueries, invalidImgTagsToRemove]);

    return { mappedHtmlWithImages, imageQueries };
};
