import { CloseButtonContainer } from "./close-button.styled";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";

type Props = {
    onClick: () => void;
    className?: string;
    testId?: string;
};

export const CloseButton = ({ className, onClick, testId }: Props) => {
    return (
        <CloseButtonContainer
            className={className}
            onClick={onClick}
            data-testid={testId}
        >
            <Icon name={Icons.xSvg} />
        </CloseButtonContainer>
    );
};
