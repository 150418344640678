import styled, { css } from "styled-components";

import { FONT_CONFIG, TextStyledProps } from "./text.interface";

export const StyledText = styled.p<TextStyledProps>`
    font-style: normal;
    font-weight: ${({ weight }) => weight};
    font-size: ${({ size }) => FONT_CONFIG[size].fontSize};
    line-height: ${({ size }) => FONT_CONFIG[size].lineHeight};
    color: ${({ color }) => color};
    text-align: ${({ align }) => align};

    ${({ truncate }) =>
        truncate &&
        css`
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        `}
`;
