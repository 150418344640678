import { useRecoilState } from "recoil";
import { useMediaQuery } from "@mantine/hooks";

import { RightModal } from "components/molecules/right-modal/right-modal.component";
import { Footer } from "components/templates/default-layout/components/footer/footer.component";
import { Header } from "components/organisms/header/header.component";
import { LeftMenu } from "components/organisms/left-menu/left-menu.component";
import { breakpoints } from "lib/breakpoints";
import { leftMenuState } from "lib/recoil/atoms/left-menu.atom";
import { DefaultLayoutProps } from "./default-layout.interface";
import {
    StyledDefaultLayout,
    StyledGeneralContent,
} from "./default-layout.styled";

export const DefaultLayout = ({
    children,
    onBack,
    pageTitle,
    showFooter,
    footerButtonText,
    footerButtonTestId,
    onFooterButtonClick,
    footerCancelButtonText,
    footerCancelButtonTestId,
    onFooterCancelButtonClick,
    rightModalIsOpen = false,
    rightModalOnClose,
    rightModalContent,
    showRightModalFooter,
    rightModalOkButtonText,
    rightModalCancelButtonText,
    rightModalOnOk,
    rightModalOnCancel,
    rightBlockIsOpen,
    isScrollable = true,
    showBackButton,
    showHeaderShadowOnTablet = true,
    isFooterButtonsDisabled,
    showCloseIconInRightModal,
}: DefaultLayoutProps) => {
    const [isActive, setIsActive] = useRecoilState(leftMenuState);
    const isMobile = useMediaQuery(`(max-width: ${`${breakpoints.xl}px`})`);

    const desktopLayout = (
        <StyledDefaultLayout isScrollable={isScrollable}>
            <LeftMenu isActive={isActive} setIsActive={setIsActive} />
            <Header
                onBack={onBack}
                isActive={isActive}
                setIsActive={setIsActive}
                pageTitle={pageTitle}
                showBackButton={showBackButton}
            />
            <StyledGeneralContent
                rightModalIsOpen={rightModalIsOpen}
                showFooter={showFooter}
                isActive={isActive}
                isScrollable={isScrollable}
            >
                {children}
            </StyledGeneralContent>
            {showFooter && (
                <Footer
                    firstButtonText={footerButtonText}
                    onFirstButtonClick={onFooterButtonClick}
                    firstButtonTestId={footerButtonTestId}
                    secondButtonText={footerCancelButtonText}
                    secondButtonTestId={footerCancelButtonTestId}
                    onSecondButtonClick={onFooterCancelButtonClick}
                    isActive={isActive}
                    isRightBlockOpen={rightBlockIsOpen}
                    disabled={isFooterButtonsDisabled}
                />
            )}
        </StyledDefaultLayout>
    );

    const mobileLayout = (
        <StyledDefaultLayout>
            <LeftMenu isActive={isActive} isMobile setIsActive={setIsActive} />
            <Header isActive={isActive} isMobile setIsActive={setIsActive} />
            <StyledGeneralContent
                showFooter={showFooter}
                isActive={isActive}
                rightModalIsOpen={rightModalIsOpen}
                isScrollable={isScrollable}
                showHeaderShadowOnTablet={showHeaderShadowOnTablet}
            >
                {children}
            </StyledGeneralContent>
            {showFooter && (
                <Footer
                    firstButtonText={footerButtonText}
                    onFirstButtonClick={onFooterButtonClick}
                    firstButtonTestId={footerButtonTestId}
                    secondButtonText={footerCancelButtonText}
                    onSecondButtonClick={onFooterCancelButtonClick}
                    secondButtonTestId={footerCancelButtonTestId}
                    isMobile
                    isActive={isActive}
                    isRightBlockOpen={rightBlockIsOpen}
                    disabled={isFooterButtonsDisabled}
                />
            )}
        </StyledDefaultLayout>
    );

    return (
        <>
            {isMobile ? mobileLayout : desktopLayout}

            <RightModal
                opened={rightModalIsOpen}
                onClose={rightModalOnClose as () => void}
                showFooter={showRightModalFooter}
                okButtonText={rightModalOkButtonText}
                cancelButtonText={rightModalCancelButtonText}
                onOk={rightModalOnOk}
                onCancel={rightModalOnCancel}
                showCloseIcon={showCloseIconInRightModal}
            >
                {rightModalContent}
            </RightModal>
        </>
    );
};
