import { useTranslation } from "next-i18next";

import { StyledDropdown } from "./language-dropdown.styled";
import { useChangeLanguage } from "lib/hooks/use-change-language";
import { LANGUAGE_OPTIONS, testIds } from "./language-dropdown.interface";

export const LanguageDropdown = () => {
    const { i18n } = useTranslation();
    const changeLanguage = useChangeLanguage();

    const onChange = (selectedLocale: string | null) => {
        if (selectedLocale) {
            changeLanguage(selectedLocale);
        }
    };

    return (
        <StyledDropdown
            defaultValue={i18n.language}
            data={LANGUAGE_OPTIONS}
            onChange={onChange}
            testId={testIds.languageDropdown}
        />
    );
};
