import { useRouter } from "next/router";

export const useInfoDrawerState = () => {
    const { push, pathname, query } = useRouter();

    return {
        open() {
            void push({
                pathname,
                query: { ...query, pageInfoDrawer: true },
            });
        },
        close() {
            void push({
                pathname,
                query: { ...query, pageInfoDrawer: false },
            });
        },
        opened: query.pageInfoDrawer === "true",
    };
};
