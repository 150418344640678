import { useRouter } from "next/router";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { StyledButton } from "./page-info.styled";
import { useUserType } from "lib/hooks/use-user-type.hook";
import { useIsMounted } from "lib/hooks/use-is-mounted.hook";
import { Drawer } from "components/organisms/drawer/drawer.component";
import { zIndexes } from "lib/z-indexes";
import { PageInfoBlock } from "./components/page-info-block/page-info-block.component";
import { PAGE_INFO_CONFIG } from "./constants/page-info-configs";
import { useInfoDrawerState } from "./hooks/use-info-drawer-state";
import { testIds } from "./page-info.interface";
import { UserType } from "lib/constants/user-type";

export const PageInfo = () => {
    const { pathname } = useRouter();
    const userType = useUserType();
    const isMounted = useIsMounted();

    const { open, close, opened } = useInfoDrawerState();

    const articleId = userType && PAGE_INFO_CONFIG[userType][pathname];

    if (userType !== UserType.B2B_USER) {
        return null;
    }

    return (
        <>
            {isMounted && (
                <StyledButton
                    onClick={open}
                    data-testid={testIds.pageInfoButton}
                >
                    <Icon name={Icons.pageInfoSvg} />
                </StyledButton>
            )}

            <Drawer
                zIndex={zIndexes.pageInfoDrawer}
                onClose={close}
                opened={opened}
            >
                <PageInfoBlock closeDrawer={close} articleId={articleId} />
            </Drawer>
        </>
    );
};
