import React from "react";
import { isString } from "lodash";

import { StyledText } from "./text.styled";
import { Tag, TextProps } from "./text.interface";
import { colors } from "lib/palette";

export const Text = ({
    children,
    size = 13,
    weight = 400,
    tag = Tag.P,
    align = "start",
    color = colors.black_2,
    testId,
    showTitle,
    ...props
}: TextProps) => {
    const title = showTitle && isString(children) ? children : undefined;

    return (
        <StyledText
            as={tag}
            align={align}
            size={size}
            color={color}
            weight={weight}
            data-testid={testId}
            title={title}
            {...props}
        >
            {children}
        </StyledText>
    );
};
