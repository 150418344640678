import React from "react";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { zIndexes } from "lib/z-indexes";
import { MenuProps, testIds } from "./menu.interface";
import {
    MenuButton,
    StyledMenu,
    StyledMenuDropdown,
    StyledMenuItem,
    StyledMenuTarget,
} from "./menu.styled";

export const Menu = ({
    menuItems,
    menuButton,
    offset,
    position = "bottom",
    testId = "menu",
}: MenuProps) => {
    return (
        <StyledMenu
            {...(offset ? { offset } : {})}
            position={position}
            withinPortal
            zIndex={zIndexes.tooltip}
            data-testid={testId}
        >
            <StyledMenuTarget>
                {menuButton || (
                    <MenuButton className="menu-button">
                        <Icon name={Icons.menuSvg} />
                    </MenuButton>
                )}
            </StyledMenuTarget>
            <StyledMenuDropdown data-testid={testIds.menuDropdown}>
                {menuItems?.map(
                    ({ testId: menuItemTestId, ...item }, index) => (
                        <StyledMenuItem
                            key={index}
                            style={{ height: "36px" }}
                            data-testid={menuItemTestId}
                            {...item}
                        />
                    ),
                )}
            </StyledMenuDropdown>
        </StyledMenu>
    );
};

Menu.displayName = "Menu";
