import { TFunction } from "next-i18next";

import { toast, ToastContent, ToastOptions } from "./toast";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Try again.";
export const DEFAULT_ERROR_MESSAGE_KEY =
    "common:errorToast.description.somethingWentWrong";

export const errorToast = (
    message?: ToastContent,
    options?: ToastOptions,
    translate?: TFunction,
) => {
    const defaultMessage = translate
        ? translate(DEFAULT_ERROR_MESSAGE_KEY, DEFAULT_ERROR_MESSAGE)
        : DEFAULT_ERROR_MESSAGE;

    return toast(message || defaultMessage, {
        ...options,
        type: "error",
    });
};
