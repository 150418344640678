import styled from "styled-components";
import { Drawer } from "@mantine/core";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledRightModal = styled(Drawer)`
    .mantine-Drawer-content {
        border-left: 1px solid ${colors.blue_3};
        flex: unset;
        width: 340px;
        box-shadow: none;
        overflow-y: auto;

        @media (max-width: ${`${breakpoints.sm}px`}) {
            border-left: none;
            width: 100%;
            height: 100vh;
        }
    }

    .mantine-Drawer-inner {
        width: 340px;
        max-width: 100%;
        right: 0;
    }

    .mantine-Drawer-body {
        padding: 0;
        height: calc(100vh - 60px);
        margin-top: 60px;
    }

    .mantine-Drawer-overlay {
        display: none;
    }

    @media (max-width: ${`${breakpoints.xl - 1}px`}) {
        .mantine-Drawer-body {
            height: 100vh;
            width: 100%;
            margin: 0;
        }

        .mantine-Drawer-overlay {
            background-color: rgb(0 0 0 / 15%);
            display: block;
            opacity: unset;
        }
    }
`;

export const StyledModalCloseButton = styled.button`
    width: max-content;
    height: max-content;
    display: flex;
    padding: 0;
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: 0.3s;
    transform: rotate(0deg);
    color: ${colors.blue_2};
    z-index: ${zIndexes.modalCloseButton};

    &:hover {
        transform: rotate(-90deg);
        transition: 0.3s;
        color: ${colors.black_1};
    }
`;

export const StyledRightModalFooter = styled.div`
    width: 100%;
    padding: 15px 20px;
    background: ${colors.white};
    position: fixed;
    left: 0;
    bottom: 0;
    border-top: 1px solid ${colors.blue_3};
    display: flex;
    gap: 10px;
`;
