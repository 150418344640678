import { uniq } from "lodash";

export const getMissedPermissions = (messages: string[]) => {
    const singlePermissionPattern = /permission denied: no (\w+) permissions/;
    const multiPermissionPattern =
        /permission denied: actor must have at least one of these permissions: "([^"]+)"/;

    let permissionsList: string[] = [];

    messages.forEach((message) => {
        const singleMatch = message.match(singlePermissionPattern);
        if (singleMatch) {
            permissionsList = [...permissionsList, singleMatch[1]];
        }

        const multiMatch = message.match(multiPermissionPattern);
        if (multiMatch) {
            permissionsList = [...permissionsList, ...multiMatch[1].split(",")];
        }
    });

    return uniq(permissionsList);
};
