import styled from "styled-components";

import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledMenuButton = styled.button`
    background-color: ${colors.white};
    padding: 8px 10px 8px 12px;
    border: 1px solid ${colors.blue_3};
    border-right: none;
    border-radius: 99px 0 0 99px;
    position: absolute;
    right: 0;
    bottom: -13.5px;
    display: flex;
    z-index: ${zIndexes.menuButton};
`;
