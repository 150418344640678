import { useCallback } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { PageInfoBlockProps, testIds } from "./page-info-block.interface";
import { ZENDESK_ARTICLE_PATH } from "lib/constants/zendesk";
import { WarningBlock } from "components/molecules/warning-block/warning-block.component";
import { MAIN_ARTICLES_PAGE_ID } from "../../constants/page-info-configs";
import { Article } from "./article/article.component";
import { StyledBody, StyledCloseButton } from "./page-info-block.styled";

export const PageInfoBlock = ({
    articleId,
    closeDrawer,
}: PageInfoBlockProps) => {
    const { t } = useTranslation("common");
    const router = useRouter();

    const handleSSORedirect = useCallback(
        (path: string) => {
            void router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    return_to: encodeURIComponent(
                        `${process.env.NEXT_PUBLIC_ZENDESK_BASE_URL}${ZENDESK_ARTICLE_PATH}/${path}`,
                    ),
                },
            });
        },
        [router],
    );

    if (articleId) {
        return (
            <Article
                articleId={articleId}
                closeDrawer={closeDrawer}
                handleSSORedirect={handleSSORedirect}
            />
        );
    }

    return (
        <StyledBody>
            <StyledCloseButton onClick={closeDrawer} />
            <WarningBlock
                message={t(
                    "pageInfoDrawer.description.noDataWarningMessage",
                    "There is no article for this page. You can search for relevant information in our knowledge base.",
                )}
                buttonText={t(
                    "pageInfoDrawer.button.goToArticles",
                    "Go to articles",
                )}
                onButtonClick={() => handleSSORedirect(MAIN_ARTICLES_PAGE_ID)}
                buttonWidth={127}
                buttonTestId={testIds.goToArticlesButton}
            />
        </StyledBody>
    );
};
