import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledButton = styled.button`
    width: 36px;
    height: 36px;
    background: ${colors.white};
    box-shadow: 0 1px 6px rgb(0 0 0 / 10%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    gap: 10px;

    & > svg {
        width: 20px;
        height: 20px;
    }
`;
