import { useTranslation } from "next-i18next";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { StyledContent } from "./no-data.styled";
import { Text } from "../text/text.component";
import { colors } from "lib/palette";
import { NoDataProps, testIds } from "./no-data.interface";

export const NoData = ({
    icon = Icons.warningEmotionLessIcon,
    message,
    testId = testIds.noData,
}: NoDataProps) => {
    const { t } = useTranslation("common");

    return (
        <StyledContent data-testid={testId}>
            <Icon name={icon} />
            <Text color={colors.black_1} align="center">
                {message || t("noData.label.noData", "No data")}
            </Text>
        </StyledContent>
    );
};
