import { forwardRef, PropsWithChildren } from "react";

import { StyledContent } from "./empty-item.styled";
import { NoData } from "components/atoms/no-data/no-data.component";

export const EmptyItem = forwardRef<HTMLDivElement, PropsWithChildren>(
    ({ children }, ref) => (
        <StyledContent ref={ref}>
            {children ? children : <NoData />}
        </StyledContent>
    ),
);

EmptyItem.displayName = "EmptyItem";
