import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledContent = styled.div`
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 40px 20px;
`;

export const StyledInfo = styled.p`
    max-width: 300px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${colors?.black_6};
`;
