import styled from "styled-components";
import { Drawer } from "@mantine/core";

export const StyledDrawer = styled(Drawer)`
    .mantine-Drawer-content {
        width: max-content;
        flex: unset;
    }

    .mantine-Drawer-body {
        padding: 0;
        height: 100%;
    }

    .mantine-Drawer-overlay {
        background-color: rgb(0 0 0 / 15%);
    }
`;
