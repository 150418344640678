import {
    SignInOnboardingTargets,
    signInOnboardingAttribute,
} from "./constants";

export const createSignInOnboardingTarget = (
    targetName: SignInOnboardingTargets,
) => `[${signInOnboardingAttribute}=${targetName}]`;

export const createSignInOnboardingTargetProps = (
    targetName: SignInOnboardingTargets,
) => ({
    [signInOnboardingAttribute]: targetName,
});
