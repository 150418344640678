import React from "react";
import { isString } from "lodash";

import { StyledButton, StyledLoaderBlock } from "./button.styled";
import { ButtonSize, ButtonProps } from "./button.interface";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { Spinner } from "../spinner/spinner.component";

export const Button = ({
    size = ButtonSize.SMALL,
    backgroundColor,
    children,
    buttonColor,
    loading = false,
    disabled = false,
    isFullWidth,
    variant = "primary",
    type = "button",
    showTitle,
    ...props
}: ButtonProps) => {
    const title = showTitle && isString(children) ? children : undefined;

    return (
        <StyledButton
            $size={size}
            buttonColor={buttonColor}
            $isFullWidth={isFullWidth}
            $loading={loading}
            disabled={disabled}
            backgroundColor={backgroundColor}
            variant={variant}
            type={type}
            title={title}
            {...props}
        >
            {loading && (
                <StyledLoaderBlock data-testid="loading-block">
                    <Spinner>
                        <Icon name={Icons.buttonLoadingSvg} />
                    </Spinner>
                </StyledLoaderBlock>
            )}
            <span>{children}</span>
        </StyledButton>
    );
};
