import styled, { css } from "styled-components";

import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";
import { HeaderProps, StyledHeaderBlockProps } from "./header.interface";

export const StyledHeader = styled.div<HeaderProps>`
    flex-shrink: 0;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.blue_3};
    height: 60px;
    padding-left: calc(
        ${({ isActive }) => (isActive ? "240px" : "64px")} + 30px
    );
    transition: 0.3s;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: ${zIndexes.header};
    gap: 20px;

    ${({ isMobile }) =>
        isMobile &&
        css`
            width: 100vw;
            height: 56px;
            padding: 5px 12px;
            z-index: ${zIndexes.mobileHeader};
        `};
`;

const StyledHeaderBlock = styled.div`
    height: 100%;
    width: max-content;
    display: flex;
    align-items: center;
`;

export const StyledHeaderLeftBlock = styled(
    StyledHeaderBlock,
)<StyledHeaderBlockProps>`
    width: 100%;
    overflow: hidden;
    gap: ${({ isMobile }) => (isMobile ? "10px" : "20px")};
`;

export const StyledHeaderRightBlock = styled(StyledHeaderBlock)`
    gap: 10px;
`;

export const StyledHeaderMobileLogo = styled.div`
    display: flex;
    width: max-content;
    height: max-content;
    cursor: pointer;

    svg {
        width: auto;
        height: 24px;
    }
`;

export const StyledHeaderButton = styled.button`
    width: 36px;
    height: 36px;
    background: ${colors.white};
    box-shadow: 0 1px 6px rgb(0 0 0 / 10%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    gap: 10px;

    & > svg {
        width: 20px;
        height: 20px;
    }
`;

export const StyledHeaderProfileButtonDesktopContent = styled.div`
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${colors.black_6};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
`;

export const StyledDesktopButtonBlock = styled.div`
    display: flex;
    width: max-content;
    align-items: center;
    gap: 10px;

    &:hover {
        cursor: pointer;
    }
`;
