import { Button } from "components/atoms/button/button.component";
import { ButtonSize } from "components/atoms/button/button.interface";
import { FooterProps } from "./footer.interface";
import { StyledFooter, StyledFooterContent } from "./footer.styled";

export const Footer = ({
    isActive,
    isMobile,
    firstButtonText,
    onFirstButtonClick,
    firstButtonTestId,
    secondButtonText,
    onSecondButtonClick,
    secondButtonTestId,
    isRightBlockOpen,
    disabled,
}: FooterProps) => {
    return (
        <StyledFooter
            isRightBlockOpen={isRightBlockOpen}
            isActive={isActive}
            isMobile={isMobile}
        >
            <StyledFooterContent>
                {firstButtonText && (
                    <Button
                        onClick={onFirstButtonClick}
                        size={ButtonSize.EXTRA_SMALL}
                        disabled={disabled}
                        data-testid={firstButtonTestId}
                    >
                        {firstButtonText}
                    </Button>
                )}
                {secondButtonText && (
                    <Button
                        variant="outlined"
                        onClick={onSecondButtonClick}
                        size={ButtonSize.EXTRA_SMALL}
                        disabled={disabled}
                        data-testid={secondButtonTestId}
                    >
                        {secondButtonText}
                    </Button>
                )}
            </StyledFooterContent>
        </StyledFooter>
    );
};
