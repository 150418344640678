import React, { ReactNode } from "react";
import { PolymorphicComponentProps } from "@mantine/utils";
import { MenuProps as MantineMenuProps } from "@mantine/core";

export type MenuItem = {
    onClick?: (e: React.MouseEvent) => void;
    children: ReactNode;
    testId?: string;
};
export interface MenuProps extends MantineMenuProps {
    menuItems?: MenuItem[];
    menuButton?: ReactNode;
    offset?: number;
    testId?: string;
}

export interface StyledMenuItemProps
    extends PolymorphicComponentProps<"div", MantineMenuProps> {}

export const testIds = {
    menuDropdown: "menu-dropdown",
};
