import styled from "styled-components";

export const StyledIconBlock = styled.div`
    width: max-content;
    height: max-content;
    display: flex;
`;

export const StyledIconsBlock = styled.div`
    display: flex;
    align-items: center;
`;
