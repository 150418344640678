import { useRecoilValue } from "recoil";
import { useCallback } from "react";

import { permissionsState } from "lib/recoil/atoms/permissions.atom";
import { Permission } from "lib/constants/permissions";

export const useCheckUserPermissions = () => {
    const authUserPermissions = useRecoilValue(permissionsState).map(
        (permission) => permission as Permission,
    );

    const checkEvery = useCallback(
        (permissions: Permission[]) =>
            permissions.every((permission) =>
                authUserPermissions.includes(permission),
            ),
        [authUserPermissions],
    );

    const checkSome = useCallback(
        (permissions: Permission[]) =>
            permissions.some((permission) =>
                authUserPermissions.includes(permission),
            ),
        [authUserPermissions],
    );

    return {
        checkEvery,
        checkSome,
    };
};
