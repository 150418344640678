import { MenuButtonProps } from "./menu-button.interface";
import { StyledMenuButton } from "./menu-button.styled";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";

export const MenuButton = ({ isActive, toggleActive }: MenuButtonProps) => {
    return (
        <StyledMenuButton onClick={toggleActive} data-testid="left-menu-button">
            <Icon name={isActive ? Icons.arrowLeftSvg : Icons.arrowRightSvg} />
        </StyledMenuButton>
    );
};
