import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledBurgerMenuButton = styled.button`
    background-color: ${colors.white};
    padding: 0;
    display: flex;
    width: max-content;
    height: max-content;
    border: none;
`;
