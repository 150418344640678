import { useQueries, UseQueryOptions } from "@tanstack/react-query";

import { AuthService } from "../api";
import { V1GetZendeskImageResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";

export const useGetZendeskArticleAttachmentsQueryKey =
    "get-zendesk-article-attachments";

type Options = Omit<
    UseQueryOptions<
        V1GetZendeskImageResponse,
        HttpErrorType,
        V1GetZendeskImageResponse,
        [typeof useGetZendeskArticleAttachmentsQueryKey, string]
    >,
    "queryKey" | "queryFn"
>;

export function useGetZendeskArticleAttachmentsQuery(
    ids: string[],
    options?: Options,
) {
    return useQueries({
        queries:
            ids.map((id) => ({
                queryKey: [useGetZendeskArticleAttachmentsQueryKey, id],
                queryFn: async () => {
                    return (await AuthService.authServiceGetZendeskImage(id))
                        .data;
                },
                options,
            })) || [],
    });
}
