import {
    toast as toastifyToast,
    ToastContent,
    ToastOptions,
} from "react-toastify";

export type { ToastContent, ToastOptions };

export const toast = (content: ToastContent, options: ToastOptions) => {
    return toastifyToast(content, options);
};
