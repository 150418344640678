import styled from "styled-components";

import { colors, shadow } from "lib/palette";
import { CloseButton } from "components/atoms/close-button/close-button.component";
import { zIndexes } from "lib/z-indexes";

export const StyledBody = styled.div`
    width: 700px;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledContent = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

export const StyledCloseButton = styled(CloseButton)`
    position: fixed;
    top: 10px;
    right: 10px;
`;

export const StyledFooter = styled.div`
    width: 100%;
    padding: 15px 20px;
    z-index: ${zIndexes.footer};
    background: ${colors.white};
    position: relative;

    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background: ${shadow.bottom};
    }
`;

export const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

export const StyledLoaderContent = styled.div`
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 40px 20px;
`;
