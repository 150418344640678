export const testIds = {
    languageDropdown: "language-dropdown",
    languageOption: "language-option",
};

const defaultLanguageOptions = [
    {
        label: "Eng",
        value: "en",
        "data-testid": `${testIds.languageOption}-en`,
    },
    {
        label: "Rus",
        value: "ru",
        "data-testid": `${testIds.languageOption}-ru`,
    },
];

if (process.env.NEXT_PUBLIC_LOCALIZATION_ENABLE_CI_MODE === "true") {
    defaultLanguageOptions.push({
        value: "cimode",
        label: "CI",
        "data-testid": `${testIds.languageOption}-cimode`,
    });
}

export const LANGUAGE_OPTIONS = defaultLanguageOptions;
