import styled from "styled-components";

import { colors } from "lib/palette";

export const CloseButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${colors.blue_4};
    transition: 0.3s;
    transform: rotate(0);

    &:hover {
        background-color: transparent;
        cursor: pointer;
        transform: rotate(-90deg);
    }
`;
