import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { shadow } from "lib/palette";
import { GlobalContentProps } from "./default-layout.interface";

export const StyledDefaultLayout = styled.div<GlobalContentProps>`
    ${({ isScrollable }) => !isScrollable && "height: 100vh"}
`;

// TODO: remove ::after when all pages will use TabletContentHeader

export const StyledGeneralContent = styled.div<GlobalContentProps>`
    transition: 250ms;
    padding-left: ${({ isActive }) => (isActive ? "240px" : "64px")};
    padding-top: 60px;
    position: relative;
    ${({ showFooter }) => showFooter && "padding-bottom: 60px;"}

    ${({ isScrollable }) => !isScrollable && "height: 100%"};

    @media (min-width: ${`${breakpoints.xl}px`}) {
        max-height: 100vh;
        overflow-y: auto;
        ${({ rightModalIsOpen }) => rightModalIsOpen && "padding-right:340px;"};
    }

    @media (max-width: ${`${breakpoints.xl}px`}) {
        padding-top: 56px;
        padding-left: 0;

        ${({ showHeaderShadowOnTablet }) =>
            showHeaderShadowOnTablet &&
            `&::after {
            content: "";
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 10px;
            left: 0;
            top: 56px;
            background: ${shadow.top};
        }`}
    }
`;
