import React from "react";

import { Button } from "components/atoms/button/button.component";
import { RightModalProps, testIds } from "./right-modal.interface";
import {
    StyledModalCloseButton,
    StyledRightModal,
    StyledRightModalFooter,
} from "./right-modal.styled";
import { Icon } from "../icon/icon.component";
import { Icons } from "../icon/icon.interface";

export const RightModal = ({
    opened,
    onClose,
    children,
    showFooter = false,
    okButtonText,
    cancelButtonText,
    onOk,
    onCancel,
    showCloseIcon = false,
}: RightModalProps) => {
    return (
        <StyledRightModal
            opened={opened}
            onClose={onClose}
            position="right"
            withCloseButton={false}
            data-testid={testIds.modalContent}
            lockScroll={false}
        >
            {showCloseIcon && (
                <StyledModalCloseButton
                    onClick={onClose}
                    data-testid={testIds.modalCloseButton}
                >
                    <Icon name={Icons.closeSvg} />
                </StyledModalCloseButton>
            )}
            {children}
            {showFooter && (
                <StyledRightModalFooter>
                    {okButtonText && (
                        <Button onClick={onOk}>{okButtonText}</Button>
                    )}
                    {cancelButtonText && (
                        <Button onClick={onCancel} variant="outlined">
                            {cancelButtonText}
                        </Button>
                    )}
                </StyledRightModalFooter>
            )}
        </StyledRightModal>
    );
};
