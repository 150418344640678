import ReactDOMServer from "react-dom/server";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";

const IMG_SRC_REGEX = /<img.*?src=['"](.*?)['"]/;

export type MappedImgTag = {
    src: string;
    imgTag: string;
    imageUrl?: string;
};

export const generateSrcsAndImageIdsFromImgTags = (imageTags: string[]) => {
    const invalidImgTagsToRemove: string[] = [];
    const imageIds: string[] = [];

    const mappedImageInfo = imageTags.reduce(
        (acc, imgTag) => {
            const src = IMG_SRC_REGEX.exec(imgTag)?.[1];
            const splitedLink = src?.split("/");

            if (!src || !splitedLink?.length || splitedLink.length === 1) {
                invalidImgTagsToRemove.push(imgTag);
                return acc;
            }

            const imageId = splitedLink[splitedLink.length - 1];

            if (!imageIds.includes(imageId)) {
                imageIds.push(imageId);
                acc[imageId] = {
                    imgTag,
                    src,
                };
            }

            return acc;
        },
        {} as Record<string, MappedImgTag>,
    );

    return {
        invalidImgTagsToRemove,
        imageIds,
        mappedImageInfo,
    };
};

export const generateNewHtmlWithImages = (
    existingHtml: string,
    mappedImageInfo: Record<string, MappedImgTag>,
    invalidImgTagsToRemove: string[],
) => {
    Object.keys(mappedImageInfo).forEach((key) => {
        if (mappedImageInfo[key].imageUrl) {
            existingHtml = existingHtml.replaceAll(
                mappedImageInfo[key].src,
                `data:image/png;base64,${mappedImageInfo[key].imageUrl}`,
            );
        } else {
            existingHtml = existingHtml.replace(
                mappedImageInfo[key].imgTag,
                ReactDOMServer.renderToStaticMarkup(
                    <Icon name={Icons.defaultArticleImageSvg} />,
                ),
            );
        }
    });

    invalidImgTagsToRemove.forEach((imgTag) => {
        existingHtml = existingHtml.replace(imgTag, "");
    });

    return existingHtml;
};
