import { memo, useMemo } from "react";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { IconBlockProps } from "./icon-block.interface";
import { StyledIconBlock, StyledIconsBlock } from "./icon-block.styled";
import { Spinner } from "components/atoms/spinner/spinner.component";

export const IconBlock = memo(({ isLoading, isDisabled }: IconBlockProps) => {
    const rightIcon = useMemo(() => {
        if (isLoading) {
            return <Icon name={Icons.loaderSvg} />;
        }
        if (isDisabled) {
            return <Icon name={Icons.lockSvg} />;
        }
        return <Icon name={Icons.downArrowSvg} />;
    }, [isLoading, isDisabled]);

    const ActiveIconBlock = useMemo(() => {
        return isLoading ? Spinner : StyledIconBlock;
    }, [isLoading]);

    return (
        <StyledIconsBlock>
            <ActiveIconBlock className="dropdown-icon-block">
                {rightIcon}
            </ActiveIconBlock>
        </StyledIconsBlock>
    );
});

IconBlock.displayName = "IconBlock";
