import { CheckPermissionsProps } from "./check-permissions.interface";
import { useCheckUserPermissions } from "lib/hooks/use-check-user-permissions.hook";
import { Permission } from "lib/constants/permissions";
import { useIsMounted } from "lib/hooks/use-is-mounted.hook";

export const CheckPermissions = ({
    children,
    permissions,
    variant = "every",
    fallback,
}: CheckPermissionsProps) => {
    const { checkEvery, checkSome } = useCheckUserPermissions();
    const isMounted = useIsMounted();

    if (!isMounted) {
        return null;
    }

    if (variant === "some" && !checkSome(permissions as Permission[])) {
        return fallback || null;
    }

    if (variant === "every" && !checkEvery(permissions as Permission[])) {
        return fallback || null;
    }

    return <>{children}</>;
};
